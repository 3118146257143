import React, { useState, useRef } from 'react';
import InputTextArea from 'react-autosize-textarea';
import Icon from '@components/Icon';
import { restProps } from '@utils/Props';
import { validateAlphabetical, validateNumeric } from '@utils/Validation';
import {
    BorderColors,
    DEFAULT_REQUIRED_TEXT,
    IOptionSelect,
    IPropsInput,
    ITextAreaProps,
    IWrapperProps,
    LabelColors,
    MAX_LENGTH_INPUTS,
    MAX_LENGTH_TEXT_AREA,
    TEXT_AREA_ROWS,
} from '.';
import './Input.scss';

export const TextInput: React.FC<IPropsInput> = props => {
    const {
        name,
        value = '',
        onChange = (): void => {},
        onPaste,
        placeholder = '',
        disabled = false,
        type = 'text',
        classesInput,
        colorText = 'gray-dark',
        maxLength = MAX_LENGTH_INPUTS,
        alphabetical = false,
        numeric = false,
    } = props;

    return (
        <WrapperInput {...props}>
            <input
                type={type}
                autoComplete="off"
                name={name}
                value={value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    onChange(e);
                }}
                placeholder={placeholder}
                disabled={disabled}
                className={`input text-${colorText} ${classesInput}`}
                onKeyDown={(e): void => {
                    alphabetical && validateAlphabetical(e);
                    numeric && validateNumeric(e);
                }}
                maxLength={maxLength}
                onPaste={onPaste}
            />
        </WrapperInput>
    );
};

const WrapperInput: React.FC<IWrapperProps> = ({
    label = '',
    disabled = '',
    children,
    required = false,
    requiredText = '*Campo obligatorio',
    classesWrapperInput = '',
    labelColor = LabelColors.BLUE,
    borderColor = BorderColors.GRAY,
    classWrapper = 'relative w-73',
    containerClass = '',
}) => {
    let wrapperClasses = `input--container border-form-focus
    ${disabled && 'input--disabled'} border ${borderColor}`;

    wrapperClasses += ' ' + classesWrapperInput;

    return (
        <div className={`flex flex-col relative ${classWrapper}`}>
            {label && <label className={`ml-1.5 text-tiny mb-1 font-poppins text-${labelColor} `}>{`${label}:`}</label>}
            <div className={`${containerClass} ${wrapperClasses} ${required && 'border-purple'}`}>{children}</div>
            {required && <label className="text-error">{requiredText ? requiredText : ''}</label>}
        </div>
    );
};

export const TextArea: React.FC<ITextAreaProps> = React.memo(
    ({
        name = '',
        value = '',
        labelText = '',
        placeholder = '',
        onChange = (): void => {},
        classesLabel = '',
        classesInput = '',
        classesWrapper = '',
        maxLength = MAX_LENGTH_TEXT_AREA,
        disabled = false,
        required = false,
        requiredText = DEFAULT_REQUIRED_TEXT,
        borderColor = BorderColors.GRAY,
    }) => (
        <div className={classesWrapper}>
            {labelText && (
                <label className={`block ml-1.5 mb-1 font-allerbold text-blue text-tiny ${classesLabel}`}>{labelText}</label>
            )}
            <div
                className={`flex items-center gap-2 rounded-md  ${
                    disabled
                        ? 'bg-gray-light'
                        : `border ${borderColor} ${
                            borderColor === BorderColors.GREEN ? 'border-form-focus' : 'focus-within:border-green'
                        } ${required && 'border-purple'}`
                }`}
            >
                <InputTextArea
                    className={`adaptable-text-area text-gray ${value ? '-dark' : ''} ${classesInput} ${
                        required && !value ? 'input--required' : ''
                    }`}
                    rows={TEXT_AREA_ROWS}
                    {...restProps({ name, value, placeholder, onChange, maxLength })}
                    disabled={disabled}
                />
            </div>
            {required && <label className="text-error">{requiredText}</label>}
        </div>
    )
);

export const SelectInput: React.FC<IPropsInput> = props => {
    const { options = [], disabled, value, placeholder = '', optionSelected = (): void => {}, classesInput = '' } = props;

    const [showOptions, setShowOptions] = useState(false);
    const [rotate, setRotate] = useState(false);
    const selectParentRef = useRef(null);

    const onClickValue = (option: IOptionSelect): void => {
        setShowOptions(false);
        setRotate(false);
        optionSelected(option);
    };

    return (
        <WrapperInput
            {...{
                ...props,
                classesWrapperInput: `${props.classesWrapperInput} relative ${showOptions ? 'border-purple' : ''}`
            }}
        >
            <div
                className={`absolute top-0 flex flex-col w-full ${disabled ? 'cursor-default' : 'cursor-pointer'}`}
                ref={selectParentRef}
            >
                <div
                    onClick={
                        disabled
                            ? (): void => {}
                            : (): void => {
                                  setShowOptions(!showOptions);
                                  setRotate(!rotate);
                              }
                    }
                    className="flex items-center justify-between h-8.25"
                    id="select-custom"
                >
                    <p
                        className={`px-2 overflow-hidden whitespace-nowrap float-left overflow-ellipsis text-sm ${
                            value ? 'text-gray-smooth' : 'text-gray-400'
                        }`}
                    >
                        {value || placeholder}
                    </p>
                    <Icon
                        name="arrowDown"
                        className={`transition duration-200 transform float-right mr-2 arrow-affair ${
                            rotate ? 'rotate-180' : 'rotate-0'
                        }`}
                    />
                </div>
                <ul
                    className={`${
                        showOptions ? 'block' : 'hidden'
                    } ${classesInput} bg-gray-light top-1 shadow-templateDesign z-10 rounded-md relative`}
                >
                    {options.map((option: IOptionSelect) => {
                        return (
                            <li
                                key={option.key}
                                className="text-sm text-center hover:bg-blue hover:text-white option-select border-p py-2.5"
                                onClick={(): void => {
                                    onClickValue(option);
                                }}
                            >
                                {option.value}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </WrapperInput>
    );
};
